import 'nodelist-foreach-polyfill';

import '../../common/components/hamburger';
import Swiper, { Pagination } from 'swiper';
import amplitude from "../../common/components/amplitude";
import { isInViewport } from "../../common/components/isInViewport";
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/pagination';
import { checkVisitIdInQuery } from "../../common/user-visit-warden";
import { executeSafely } from "../../common/components/executeSafety";
import { logImgLoadError } from "../../common/components/ImglogError";

document.addEventListener('DOMContentLoaded', () => {
    init();

    // eslint-disable-next-line no-unused-vars
    const reviewSlider = new Swiper('.root__users', {
        modules: [Pagination],
        spaceBetween: 16,
        slidesPerView: 1.5,
        simulateTouch: false,
        scrollbarDraggable: true,
        loop: true,
        centeredSlides: true,
        pagination: {
            el: '.swiper-pagination-users',
            clickable: true,
        },
        breakpoints: {
            1366: {
                loop: false,
                slidesPerView: 3,
                centeredSlides: false,
            },
        },
    });
    // eslint-disable-next-line no-unused-vars
    const commandsSlider = new Swiper('.root__plan-comm-slider', {
        spaceBetween: 16,
        slidesPerView: 2.5,
        loop: false,
    });
    const buttonView = document.querySelector('#viewportAnchor');

    const updateCounterOnce = (() => {
        let called = false;

        return () => {
            if (!called) {
                const intervalId = setInterval(updateCounter, 20);
                const counterSelector = document.querySelector('.root__statistic-value');
                let count = 0;

                function updateCounter() {
                    const countTarget = parseInt(counterSelector.getAttribute('data-count'), 10);

                    if (count < countTarget) {
                        counterSelector.innerHTML = count + '%';
                    } else {
                        counterSelector.innerHTML = countTarget + '%';
                        clearInterval(intervalId);
                    }

                    count++;
                }

                called = true;
            }
        };
    })();

    function initAmplitude() {
        const queryParams = new URLSearchParams(window.location.search);

        const indentifyData = new amplitude.Identify()
            .set('quiz_url', window.location.pathname)
            .set('source', queryParams.has('fbclid') ? 'fb' : 'none')
            .set('test', queryParams.has('test') ? 'creative' : 'none')
            .set('visit_params', String(queryParams).replace('?', ''))
            .set('campaign', queryParams.has('utm_campaign') ? 'utm_campaign' : 'none')
            .set('adset', queryParams.has('utm_content') ? 'utm_content' : 'none')
            .set('ad', queryParams.has('ad.id') ? queryParams.get('ad.id') : 'none')
            .set('campaign_name', queryParams.has('campaign_name') !== -1 ? 'campaign_name' : 'none')
            .set('adset_name', queryParams.has('adset_name') !== -1 ? 'adset_name' : 'none')
            .set('ad_name', queryParams.has('ad_name') !== -1 ? 'ad_name' : 'none');

        amplitude.identify(indentifyData);
        amplitude.logEvent("Main page view");
    }

    function sendEventTakeQuiz() {
        const takeQuizBtn = document.querySelectorAll('.takeQuiz');

        takeQuizBtn.forEach((el) => {
            el.addEventListener('click', () => {
                amplitude.logEvent("Take quiz");
            });
        });
    }

    function sendEventRootLogin() {
        const takeQuizBtn = document.getElementById('rootLogin');

        takeQuizBtn.addEventListener('click', () => {
            amplitude.logEvent("Root Login");
        });
    }

    function sendEventLogIn() {
        const logInBtn = document.getElementById('logInBtn');

        if (!logInBtn) {
            return;
        }

        logInBtn.addEventListener('click', () => {
            amplitude.logEvent("Log in");
        });
    }

    function setLocalStorageParamIfUserFromRootDomain() {
        localStorage.setItem('Root', 'yes');
    }

    localStorage.setItem('OfferText', 'true');

    // Call update counter once
    window.addEventListener('scroll', () => {
        if (isInViewport(buttonView)) {
            updateCounterOnce();
        }
    });

    function init() {
        executeSafely(initAmplitude);
        executeSafely(sendEventTakeQuiz);
        executeSafely(sendEventLogIn);
        executeSafely(sendEventRootLogin);
        executeSafely(setLocalStorageParamIfUserFromRootDomain);
        executeSafely(checkVisitIdInQuery);
        executeSafely(logImgLoadError);
    }
});
